import { useIntl } from "react-intl";

import { ScoreEnum } from "@bees-grow-shared/services";
import {
  Copy,
  Edit2,
  Phone,
  PhoneOff,
  ThumbsDown,
  ThumbsUp,
} from "@hexa-ui/icons";

import ActionButton from "../phoneRankItem/components/ActionButton";
import * as Styles from "./ActionList.styles";

interface PhoneRankListProps {
  type?: string;
  score: ScoreEnum;
}

const actionIcons = [
  <Phone size="tiny" key="click_call" />,
  <Copy size="tiny" key="copy_number" />,
  <Edit2 size="tiny" key="edit_number" />,
];

const voteIcons = [
  <PhoneOff size="tiny" key="inactive" />,
  <ThumbsDown size="tiny" key="no_answer" />,
  <ThumbsUp size="tiny" key="successful_call" />,
];

export default function ActionList({
  type,
  score,
}: Readonly<PhoneRankListProps>) {
  const { formatMessage } = useIntl();

  if (type === "rate") {
    if (score === ScoreEnum.INACTIVE) {
      return (
        <Styles.Button
          variant="secondary"
          size="small"
          data-testid={`ActionList-rate-inactive`}
        >
          {formatMessage({ id: "PhoneNumbers.BUTTON_ACTIVATE" })}
        </Styles.Button>
      );
    }
    return (
      <Styles.ActionList data-testid="ActionList-rate">
        {voteIcons.map((item) => (
          <ActionButton actionIcon={item} key={item.key} />
        ))}
      </Styles.ActionList>
    );
  }

  if (score === ScoreEnum.INACTIVE) {
    return <></>;
  }

  return (
    <Styles.ActionList data-testid="ActionList">
      {actionIcons.map((item) => (
        <ActionButton actionIcon={item} key={item.key} />
      ))}
    </Styles.ActionList>
  );
}
