import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  UpdateButton: {
    TEXT: "Atualizar",
  },
  AccountOverview: {
    TITLE: "Visão geral do PDV",
  },
  ClubB: {
    TITLE: "Club B",
  },
  PhoneNumbers: {
    TITLE: "Números de telefone",
    NUMBER_RATE: {
      GOOD: "Bom número",
      BAD: "Número ruim",
      NEUTRAL: "Número neutro",
    },
    TOOLTIP: {
      INACTIVE: "Inativo",
      NO_ANSWER: "Sem resposta",
      SUCCESSFUL_CALL: "Chamada bem-sucedida",
      CLICK_CALL: "Clique para chamar",
      COPY_NUMBER: "Copiar número",
      EDIT_NUMBER: "Editar número",
    },
    PHONE_TYPE: {
      INPUTTED_PHONE: "Adicionado pelo agente",
      BEES_PHONE: "Telefone BEES",
      ERP_PHONE: "Telefone ERP",
    },
    BUTTON_ACTIVATE: "Ativar",
  },
  Tasks: {
    TITLE: "Tarefas de ligação",
    UNABLE_TO_LOAD: "Não foi possível carregar as informações das tarefas.",
    TRY_AGAIN: "Tentar novamente",
    EMPTY: "Não há tarefas atribuídas a esta POC.",
    GO_TO_TASKS: "Ir para tarefas",
    Global: {
      Title: {
        TOPLINE: "Tarefa de topline",
        MARKETPLACE: "Tarefa de marketplace",
      },
      Description: {
        TOPLINE: "Recomendar produtos com base na ordem sugerida.",
        MARKETPLACE: "Incentivar a compra de produtos do marketplace.",
      },
    },
    Local: {
      Description: {
        DEFAULT: "Tarefa personalizada criada pelas equipes de operações.",
      },
    },
  },
  PaymentMethod: {
    Methods: {
      CREDIT: "Crédito",
      CASH: "Dinheiro",
      BANK_SLIP: "Boleto",
      BANK_SLIP_INSTALLMENT: "Boleto Parcelado",
      CHECK: "Cheque",
    },
  },
  LastOrders: {
    PREVIEW_TITLE: "Visualização dos últimos pedidos",
    SHOW_FULL_TABLE: "Mostrar tabela completa",
    COPY_CLIPBOARD: "Copiar",
    Error: {
      MESSAGE: "Não foi possível carregar as informações dos últimos pedidos.",
      ACTION: "Tente novamente",
    },
    EmptyState: {
      MESSAGE:
        "Não há pedidos para mostrar. Assim que esta POC fizer um pedido, ele aparecerá aqui.",
    },
    Table: {
      CHANNEL: "Canal",
      ORDER_ID: "ID do pedido",
      STATUS: "Status",
      PAYMENT: "Pagamento",
      PLACED_ON: "Colocado em",
      PRODUCTS: "Produtos",
      DELIVERY: "Entrega",
      TOTAL: "Total",
    },
    Status: {
      PARTIAL_DELIVERY: "Entregue parcialmente",
      INVOICED: "Faturado",
      PENDING: "Pedido lançado",
      PENDING_PAYMENT: "Pagamento Pendente",
      PENDING_CANCELLATION: "Cancelamento em andamento",
      PLACED: "Pedido lançado",
      MODIFIED: "Aprovado com modificações",
      CANCELLED: "Pedido cancelado",
      CONFIRMED: "Pedido confirmado",
      AVAILABLE: "Aguardando retirada",
      DELIVERED: "Entregue",
      IN_TRANSIT: "Saiu pra entrega",
      DENIED: "Pedido Cancelado",
    },
  },
  Notes: {
    TITLE: "Notes",
  },
};

export default ptBR;
