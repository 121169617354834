import PhoneRankHeader from "./components/phoneRankHeader/PhoneRankHeader";
import PhoneRankList from "./components/phoneRankList/PhoneRankList";
import { usePhoneRank } from "./hooks/usePhoneRank";
import * as Styles from "./PhoneNumbers.styles";

export default function PhoneNumbers() {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    paginatedPhoneListData,
    isLoading,
    containerRef,
    error,
    currentPage,
    setCurrentPage,
    pageSize,
    total,
  } = usePhoneRank();

  if (isLoading) return <Loading />;

  return (
    <Styles.Container
      ref={containerRef}
      data-testid={`${PhoneNumbers.name}-container`}
    >
      <PhoneRankHeader
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        hasError={error}
      />

      <PhoneRankList
        isDropdownOpen={isDropdownOpen}
        phoneListData={paginatedPhoneListData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        total={total}
      />
    </Styles.Container>
  );
}

const PhoneSkeleton = () => (
  <Styles.PhoneContainer>
    <Styles.PhoneColumn>
      <Styles.PhoneColumnTitle />
      <Styles.PhoneColumnSubtitle />
      <Styles.PhoneColumnContent />
    </Styles.PhoneColumn>
    <Styles.PhoneColumn>
      <Styles.PhoneColumnTitle />
      <Styles.PhoneRow>
        <Styles.PhoneIcon />
        <Styles.PhoneIcon />
        <Styles.PhoneIcon />
      </Styles.PhoneRow>
    </Styles.PhoneColumn>
  </Styles.PhoneContainer>
);

export function Loading() {
  return (
    <>
      <Styles.TitleLoader data-testid={`${Loading.name}-skeleton`} />
      {Array.from({ length: 5 }).map((_, index) => (
        <PhoneSkeleton key={`${PhoneSkeleton.name}-${index}`} />
      ))}
    </>
  );
}
