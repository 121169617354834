import { useIntl } from "react-intl";

import { UpdateButton } from "@bees-grow-shared/components";
import { lastOrdersSharedService } from "@bees-grow-shared/services";

import * as Styled from "./Header.styles";

interface HeaderProps {
  getLastOrders: () => void;
  isLastOrdersError: boolean;
  isLastOrdersEmpty: boolean;
}

export function Header({
  getLastOrders,
  isLastOrdersError,
  isLastOrdersEmpty,
}: Readonly<HeaderProps>) {
  const lastOrdersService = lastOrdersSharedService();
  const { formatMessage } = useIntl();

  const handleGoToFullTable = () => {
    lastOrdersService.setLastOrdersState({
      showLastOrderModal: true,
    });
  };

  const shouldShowActions = !isLastOrdersError && !isLastOrdersEmpty;

  return (
    <Styled.HeaderContainer>
      <Styled.Title size="H4" data-testid="last-orders-header">
        {formatMessage({ id: "LastOrders.PREVIEW_TITLE" })}
      </Styled.Title>
      {shouldShowActions && (
        <>
          <Styled.UpdateButtonContainer>
            <UpdateButton
              text={formatMessage({ id: "UpdateButton.TEXT" })}
              action={getLastOrders}
            />
          </Styled.UpdateButtonContainer>
          <Styled.Link
            hasUnderline={false}
            onClick={handleGoToFullTable}
            data-testid="full-table-link"
          >
            {formatMessage({ id: "LastOrders.SHOW_FULL_TABLE" })}
          </Styled.Link>
        </>
      )}
    </Styled.HeaderContainer>
  );
}
