import { useIntl } from "react-intl";

import { Button, Divider } from "@hexa-ui/components";

import EmptyStateMediumIcon from "@/assets/svg/EmptyStateMediumIcon";
import PhoneActionModal from "@/components/phoneNumbers/components/phoneActionModal/PhoneActionModal";
import useGeneralError from "@/hooks/useGeneralError/useGeneralError";

import ClubB from "../../components/clubB/ClubB";
import LastOrdersPreview from "../../components/lastOrdersPreview/LastOrdersPreview";
import Notes from "../../components/notes/Notes";
import PhoneNumbers from "../../components/phoneNumbers/PhoneNumbers";
import Tasks from "../../components/tasks/Tasks";
import * as Styled from "./AccountPage.styles";

interface ErrorBehaviorProps {
  errorTitleLabel: string;
  errorDescriptionLabel: string;
  errorButtonLabel: string;
  onReload: () => void;
}

export default function AccountPage() {
  const { formatMessage } = useIntl();

  const { error, handleRetryGeneralError } = useGeneralError();

  return (
    <>
      <PhoneActionModal />
      <Styled.AccountContainer>
        <Styled.AccountTitle
          size="H3"
          data-testid={`${AccountPage.name}-title`}
        >
          {formatMessage({ id: "AccountOverview.TITLE" })}
        </Styled.AccountTitle>
        {error ? (
          <ErrorBehavior
            errorTitleLabel={formatMessage({ id: "GeneralError.TITLE" })}
            errorDescriptionLabel={formatMessage({
              id: "GeneralError.DESCRIPTION",
            })}
            errorButtonLabel={formatMessage({ id: "GeneralError.ACTION" })}
            onReload={handleRetryGeneralError}
          />
        ) : (
          <Styled.CardContainer
            elevated="small"
            border="medium"
            data-testid={`${AccountPage.name}-container`}
          >
            <Styled.AsideColumn>
              <PhoneNumbers />
              <Divider decorative />
              <Notes />
            </Styled.AsideColumn>
            <Styled.ColumnDivider orientation="vertical" decorative />
            <Styled.MainColumn>
              <Tasks />
              <Divider decorative />
              <LastOrdersPreview />
              <Divider decorative />
              <ClubB />
            </Styled.MainColumn>
          </Styled.CardContainer>
        )}
      </Styled.AccountContainer>
    </>
  );
}

function ErrorBehavior({
  errorTitleLabel,
  errorDescriptionLabel,
  errorButtonLabel,
  onReload,
}: Readonly<ErrorBehaviorProps>) {
  return (
    <Styled.ErrorContainer
      elevated="small"
      border="medium"
      data-testid="account-page-error"
    >
      <EmptyStateMediumIcon width="200" height="200" />
      <Styled.ErrorTitle size="H3">{errorTitleLabel}</Styled.ErrorTitle>
      <Styled.ErrorParagraph>{errorDescriptionLabel}</Styled.ErrorParagraph>
      <Button variant="secondary" onClick={onReload}>
        {errorButtonLabel}
      </Button>
    </Styled.ErrorContainer>
  );
}
