import { Phone } from "@bees-grow-shared/services";
import { Pagination } from "@hexa-ui/components";

import PhoneRankItem from "../phoneRankItem/PhoneRankItem";
import * as Styles from "./PhoneRankList.styles";

interface PhoneRankListProps {
  isDropdownOpen: boolean;
  phoneListData: Array<Phone>;
  currentPage: number;
  setCurrentPage: (value: number | ((prev: number) => number)) => void;
  pageSize: number;
  total: number;
}

export default function PhoneRankList({
  phoneListData,
  isDropdownOpen,
  currentPage,
  setCurrentPage,
  pageSize,
  total,
}: PhoneRankListProps) {
  const minimumPageSize = 5;

  return (
    <Styles.Wrapper isDropdownOpen={isDropdownOpen} data-testid="PhoneRankList">
      {phoneListData?.map((item: Phone, index) => (
        <PhoneRankItem
          phoneListData={item}
          key={`PhoneRankList-${index}`}
          data-testid={`PhoneRankList-item-${index}`}
        />
      ))}
      {total > minimumPageSize && (
        <Styles.PaginationContainer data-testid="Pagination">
          <Pagination
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            total={total}
            showQuantityIndicator={false}
            showPageSizeSelector={false}
          />
        </Styles.PaginationContainer>
      )}
    </Styles.Wrapper>
  );
}
