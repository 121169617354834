import { FormEvent, useCallback, useEffect } from "react";

import {
  accountSharedService,
  noteSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useNotes() {
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);
  const noteService = noteSharedService();
  const {
    upsertNoteStatus: { isLoading: isLoadingUpsertNote },
    getNoteStatus: { isLoading: isLoadingGetNote, error: getNoteError },
    customerNotes,
  } = useSharedUnit(noteService);

  const handleGetNote = useCallback(() => {
    noteService.getNote({
      accountId,
      vendorId,
    });
  }, [accountId, noteService, vendorId]);

  useEffect(handleGetNote, [handleGetNote]);

  const handleUpsertNote = () => {
    noteService.upsertNote({
      accountId,
      vendorId,
      customerNotes,
    });
  };

  const handleChangeCustomerNotes = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      noteService.setCustomerNotes({
        ...customerNotes,
        note: event.currentTarget.value,
      });
    },
    [customerNotes, noteService],
  );

  return {
    getNoteError,
    customerNotes,
    handleGetNote,
    handleUpsertNote,
    isLoadingGetNote,
    isLoadingUpsertNote,
    handleChangeCustomerNotes,
  };
}
