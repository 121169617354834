import { useIntl } from "react-intl";

import { RetryAlert, TableSkeleton } from "@bees-grow-shared/components";

import EmptyStateIcon from "@/assets/lastOrders/EmptyStateIcon";

import { Header } from "./components/header/Header";
import TablePreview from "./components/tablePreview/TablePreview";
import { useLastOrdersPreview } from "./hooks/useLastOrdersPreview";
import * as Styled from "./LastOrdersPreview.styles";

export interface GetLastOrdersProps {
  getLastOrders: () => void;
}

export default function LastOrdersPreview() {
  const {
    isLastOrderLoading,
    isLastOrdersEmpty,
    isLastOrdersError,
    getLastOrders,
  } = useLastOrdersPreview();

  if (isLastOrderLoading) {
    return <TableSkeleton columns={7} rows={5} />;
  }

  return (
    <>
      <Header getLastOrders={getLastOrders} />
      {isLastOrdersError && <ErrorBoundary getLastOrders={getLastOrders} />}
      {!isLastOrdersError && isLastOrdersEmpty && <EmptyState />}
      {!isLastOrdersError && !isLastOrdersEmpty && <TablePreview />}
    </>
  );
}

function ErrorBoundary({ getLastOrders }: Readonly<GetLastOrdersProps>) {
  const { formatMessage } = useIntl();
  return (
    <Styled.ErrorContainer>
      <RetryAlert
        message={formatMessage({ id: "LastOrders.Error.MESSAGE" })}
        actionLabel={formatMessage({ id: "LastOrders.Error.ACTION" })}
        action={getLastOrders}
      />
    </Styled.ErrorContainer>
  );
}

function EmptyState() {
  const { formatMessage } = useIntl();
  return (
    <Styled.EmptyContainer>
      <EmptyStateIcon />
      <Styled.EmptyText data-testid="empty-state-text">
        {formatMessage({ id: "LastOrders.EmptyState.MESSAGE" })}
      </Styled.EmptyText>
    </Styled.EmptyContainer>
  );
}
