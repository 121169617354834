import { tokens } from "@bees-grow-shared/theme";
import { Heading, TextLink } from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Heading)`
  margin: 0;
`;

export const UpdateButtonContainer = styled.div`
  flex: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1rem 0;
`;

export const Link = styled(TextLink)`
  color: ${tokens.color.primitive.solid.blue["blue-70"]};
  font-size: 0.875rem;
`;
