import { useCallback, useMemo } from "react";

import {
  accountSharedService,
  lastOrdersSharedService,
  missionSharedService,
  phoneRankSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export default function useGeneralError() {
  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const lastOrdersService = lastOrdersSharedService();
  const phoneRankService = phoneRankSharedService();
  const missionService = missionSharedService();

  const { error: isLastOrdersError } = useSharedUnit(lastOrdersService);
  const { error: isPhoneRankError } = useSharedUnit(phoneRankService);
  const {
    prioritizedMissions: { error: isMissionServiceError },
  } = useSharedUnit(missionService);

  const handleRetryGeneralError = useCallback(() => {
    lastOrdersService.getLastOrders({ accountId, vendorId });
    phoneRankService.getPhoneRank({ accountId, vendorId });
    missionService.setPrioritizedMissions({ accountId, vendorId });
  }, [
    accountId,
    lastOrdersService,
    missionService,
    phoneRankService,
    vendorId,
  ]);

  const error = useMemo(
    () => isLastOrdersError && isPhoneRankError && isMissionServiceError,
    [isLastOrdersError, isMissionServiceError, isPhoneRankError],
  );

  return {
    error,
    handleRetryGeneralError,
  };
}
