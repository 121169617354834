import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader, TextLink } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  li {
    background: white;
  }
  li:nth-child(4n-1),
  li:nth-child(4n-2) {
    background: ${tokens.color.primitive.solid.neutral["neutral-10"]};
  }
`;

export const TasksHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const TasksHeaderError = styled.div`
  margin-bottom: 1rem;
`;

export const TasksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Link = styled(TextLink)`
  color: ${tokens.color.primitive.solid.blue["blue-70"]};
  font-size: 0.875rem;
`;
export const TitleLoader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 8rem;
  margin-bottom: 0.625rem;
`;

export const TaskTitleLoader = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 7rem;
`;

export const TaskDescriptionLoader = styled(SkeletonLoader)`
  height: 0.875rem;
  width: 12.5rem;
`;

export const TasksContainerLoader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.5rem;
  row-gap: 0.375rem;
`;

export const TaskLoader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
`;
