import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  UpdateButton: {
    TEXT: "Update",
  },
  AccountOverview: {
    TITLE: "POC overview",
  },
  ClubB: {
    TITLE: "Club B",
  },
  PhoneNumbers: {
    TITLE: "Phone numbers",
    NUMBER_RATE: {
      GOOD: "Good number",
      BAD: "Bad number",
      NEUTRAL: "Neutral number",
    },
    TOOLTIP: {
      INACTIVE: "Inactivate",
      NO_ANSWER: "No answer",
      SUCCESSFUL_CALL: "Successful call",
      CLICK_CALL: "Click to call",
      COPY_NUMBER: "Copy number",
      EDIT_NUMBER: "Edit number",
    },
    PHONE_TYPE: {
      INPUTTED_PHONE: "Added by agent",
      BEES_PHONE: "BEES phone",
      ERP_PHONE: "ERP phone",
    },
    BUTTON_ACTIVATE: "Activate",
  },
  Tasks: {
    TITLE: "Call tasks",
    UNABLE_TO_LOAD: "Unable to load the tasks information.",
    TRY_AGAIN: "Try again",
    EMPTY: "There is no tasks assigned to this POC.",
    GO_TO_TASKS: "Go to tasks",
    Global: {
      Title: {
        TOPLINE: "Topline task",
        MARKETPLACE: "Marketplace task",
      },
      Description: {
        TOPLINE: "Recommend products based on the suggested order.",
        MARKETPLACE: "Encourage the purchase of marketplace products.",
      },
    },
    Local: {
      Description: {
        DEFAULT: "Customized task created by the operations teams.",
      },
    },
  },
  PaymentMethod: {
    Methods: {
      CREDIT: "Credit",
      CASH: "Cash",
      BANK_SLIP: "Bank slip",
      BANK_SLIP_INSTALLMENT: "Bank slip installment",
      CHECK: "Check",
    },
  },
  LastOrders: {
    PREVIEW_TITLE: "Last orders preview",
    SHOW_FULL_TABLE: "Show full table",
    COPY_CLIPBOARD: "Copy",
    Error: {
      MESSAGE: "Unable to load last orders information.",
      ACTION: "Try again",
    },
    EmptyState: {
      MESSAGE:
        "No orders to show. Once this POC places an order, it'll show up here.",
    },
    Table: {
      CHANNEL: "Channel",
      ORDER_ID: "Order ID",
      STATUS: "Status",
      PAYMENT: "Payment",
      PLACED_ON: "Placed on",
      PRODUCTS: "Products",
      DELIVERY: "Delivery",
      TOTAL: "Total",
    },
    Status: {
      PARTIAL_DELIVERY: "Partially delivered",
      INVOICED: "Billed",
      PENDING: "Order placed",
      PENDING_PAYMENT: "Payment pending",
      PENDING_CANCELLATION: "Cancellation pending",
      PLACED: "Order placed",
      MODIFIED: "Processing with modifications",
      CANCELLED: "Canceled",
      CONFIRMED: "Processing",
      AVAILABLE: "Ready for pickup",
      DELIVERED: "Delivered",
      IN_TRANSIT: "Out for delivery",
      DENIED: "Declined",
    },
  },
  Notes: {
    TITLE: "Notes",
  },
};

export default enUS;
