import { tokens } from "@bees-grow-shared/theme";
import { Card, Divider, Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const AccountContainer = styled.div`
  width: 100%;
  max-width: 1784px;
  margin: 0 auto;
`;

export const AccountTitle = styled(Heading)`
  margin: 1.5rem 0 1rem 0;
`;

export const CardContainer = styled(Card)`
  display: grid;
  grid-template-columns: auto auto 1fr;
`;

export const AsideColumn = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 374px;
  padding: ${tokens.measure.space["spacing-6"]};
`;

export const ColumnDivider = styled(Divider)`
  height: auto !important;
`;

export const MainColumn = styled.div`
  width: 100%;
  padding: ${tokens.measure.space["spacing-6"]};
  overflow-x: auto;
`;
