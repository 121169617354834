import { useIntl } from "react-intl";

import { useToast } from "@bees-grow-shared/business-components";
import {
  accountSharedService,
  ClassificationEnum,
  phoneRankSharedService,
  PhoneRequestType,
  PhoneTypeEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { z } from "zod";

import { usePhoneManagerModal } from "@/contexts/PhoneManagerModalContext/PhoneManagerModalContext";

const phoneRankSchema = z.object({
  phone: z.string().refine((value) => {
    const phoneRegex = /^\d+(\s\d+)*$/;
    return phoneRegex.test(value);
  }),
  name: z.string().min(2),
});

type PhoneRankSchema = z.infer<typeof phoneRankSchema>;

const initialCharCodeOfNumbers = 48;
const endCharCodeOfNumbers = 57;

interface UsePhoneRankProps {
  handlePhoneRankAction: (data: PhoneRankSchema) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  phoneRankSchema: z.ZodObject<
    {
      phone: z.ZodEffects<z.ZodString, string, string>;
      name: z.ZodString;
    },
    "strip",
    z.ZodTypeAny,
    {
      phone?: string;
      name?: string;
    }
  >;
}

export function usePhoneRankForm(): UsePhoneRankProps {
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();
  const toast = useToast();
  const { handleCloseModal, modalData } = usePhoneManagerModal();

  const { formatMessage } = useIntl();

  const { accountId, vendorId } = useSharedUnit(accountService);

  const handlePhoneRankAction = (data: PhoneRankSchema) => {
    const phone: PhoneRequestType = {
      contactName: data.name,
      phoneNumber: data.phone,
      classification: ClassificationEnum.INPUTTED,
      phoneType: PhoneTypeEnum.INPUTTED_PHONE,
    };
    if (modalData) {
      phoneRankService
        .updatePhoneRank({
          params: { accountId, vendorId, phoneNumber: modalData?.phoneNumber },
          phone,
        })
        .then(() => {
          toast({
            showCloseButton: true,
            type: "success",
            message: formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.EDIT_SUCCESS_MESSAGE",
            }),
          });
        })
        .catch(() => {
          toast({
            showCloseButton: true,
            type: "error",
            message: formatMessage({
              id: "PhoneNumbers.AddEditPhoneModal.EDIT_ERROR_MESSAGE",
            }),
          });
        });
      handleCloseModal();
      return;
    }

    const phones: Array<PhoneRequestType> = [phone];
    phoneRankService
      .postPhoneRank({
        params: { accountId, vendorId },
        phones,
      })
      .then(() => {
        toast({
          showCloseButton: true,
          type: "success",
          message: formatMessage({
            id: "PhoneNumbers.AddEditPhoneModal.CREATE_SUCCESS_MESSAGE",
          }),
        });
      })
      .catch(() => {
        toast({
          showCloseButton: true,
          type: "error",
          message: formatMessage({
            id: "PhoneNumbers.AddEditPhoneModal.CREATE_ERROR_MESSAGE",
          }),
        });
      });
    handleCloseModal();
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (
      charCode < initialCharCodeOfNumbers ||
      charCode > endCharCodeOfNumbers
    ) {
      event.preventDefault();
    }
  };

  return {
    handlePhoneRankAction,
    handleKeyPress,
    phoneRankSchema,
  };
}
