import { Table } from "@hexa-ui/components";

import { useTablePreview } from "./hooks/useTablePreview";
import * as Styled from "./TablePreview.styles";

export default function TablePreview() {
  const { columns, parsedLastOrders, handleOnRowClick } = useTablePreview();

  return (
    <Styled.TableContainer>
      <Table
        pagination={false}
        columns={columns}
        data={parsedLastOrders}
        onRow={handleOnRowClick}
      />
    </Styled.TableContainer>
  );
}
