import { tokens } from "@bees-grow-shared/theme";
import { Heading, Button as HexaButton, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const Button = styled(HexaButton)`
  padding-left: ${tokens.measure.space["spacing-4"]};
  padding-right: ${tokens.measure.space["spacing-4"]};
  padding-top: ${tokens.measure.space["spacing-2"]};
  padding-bottom: ${tokens.measure.space["spacing-2"]};
`;

export const Description = styled(Paragraph)`
  width: 20.0625rem;
`;

export const TitleHeading = styled(Heading)``;

export const Number = styled.span`
  font-weight: bold;
`;
