import * as Styled from "./PlacedColumn.styles";

interface PlacedColumnProps {
  placed: string;
}

export function PlacedColumn({ placed }: Readonly<PlacedColumnProps>) {
  return (
    <Styled.Container>
      <Styled.Text>{placed}</Styled.Text>
    </Styled.Container>
  );
}
