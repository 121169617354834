import { tokens } from "@bees-grow-shared/theme";
import { Card, Divider, Heading, Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const AccountContainer = styled.div`
  width: 100%;
  max-width: 1784px;
  margin: 0 auto;
`;

export const AccountTitle = styled(Heading)`
  margin: 1.5rem 0 1rem 0;
`;

export const CardContainer = styled(Card)`
  display: grid;
  grid-template-columns: auto auto 1fr;
`;

export const AsideColumn = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 374px;
  padding: ${tokens.measure.space["spacing-6"]};
`;

export const ColumnDivider = styled(Divider)`
  height: auto !important;
`;

export const MainColumn = styled.div`
  width: 100%;
  padding: ${tokens.measure.space["spacing-6"]};
  overflow-x: auto;
`;

export const ErrorContainer = styled(Card)`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${tokens.measure.space["spacing-4"]};
  padding: ${tokens.measure.space["spacing-8"]} 0;
  width: 100%;
  height: 100%;
`;

export const ErrorTitle = styled(Heading)`
  font-family: ${tokens.fonts["font-family"].Barlow};
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  text-align: center;
`;

export const ErrorParagraph = styled(Paragraph)`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-size: ${tokens.measure["font-size"]["fontsize-3"]}16px;
  font-weight: ${tokens.fonts["font-weight"].normal};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  text-align: center;
  color: ${tokens.color.primitive.transparent.black["black-56"]};
  white-space: pre-line;
  gap: 0rem;
`;
