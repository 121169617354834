import { useIntl } from "react-intl";

import {
  ctiSharedService,
  PhoneResponseType,
  ScoreEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";

interface UsePhoneRankItem {
  phoneContact: string;
  phoneType: string;
  currentPhone: string;
  score: ScoreEnum;
  isConnected: boolean;
  tagLabel: string;
}
export function usePhoneRankItem(
  phoneListData: PhoneResponseType,
): UsePhoneRankItem {
  const { formatMessage } = useIntl();
  const ctiService = ctiSharedService();
  const { calledNumbers, lastCopiedNumber, isConnected } =
    useSharedUnit(ctiService);

  const {
    phoneNumber: currentPhone,
    phoneContact,
    phoneType,
    score,
  } = phoneListData;

  const getTagLabel = () => {
    if (isConnected) {
      const isCurrentPhoneCalled = calledNumbers.some(
        (calledNumber) => calledNumber.phoneNumber === currentPhone,
      );

      if (isCurrentPhoneCalled) {
        return formatMessage({ id: "PhoneNumbers.CALLED_TAG" });
      }
    } else if (lastCopiedNumber === currentPhone) {
      return formatMessage({ id: "PhoneNumbers.COPIED_TAG" });
    }
    return null;
  };

  const tagLabel = getTagLabel();

  return {
    phoneContact,
    phoneType,
    currentPhone,
    score,
    isConnected,
    tagLabel,
  };
}
