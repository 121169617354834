import { useIntl } from "react-intl";

import { ClassificationEnum } from "@bees-grow-shared/services";
import { Dialog } from "@hexa-ui/components";

import { usePhoneActionModal } from "@/contexts/PhoneActionModalContext/PhoneActionModalContext";

import * as Styles from "./PhoneActionModal.styles";

export default function PhoneActionModal() {
  const { formatMessage } = useIntl();
  const { Close, Root } = Dialog;
  const { isModalOpen, handleCloseModal, modalData } = usePhoneActionModal();

  const isActivate = modalData?.type === ClassificationEnum.ACTIVATE;
  const ActivationType = isActivate
    ? formatMessage({ id: "PhoneNumbers.Modal.Button.ACTIVATE" })
    : formatMessage({ id: "PhoneNumbers.Modal.Button.DEACTIVATE" });

  const ActivationTitle = isActivate
    ? formatMessage({ id: "PhoneNumbers.Modal.ACTIVATE_TITLE" })
    : formatMessage({ id: "PhoneNumbers.Modal.DEACTIVATE_TITLE" });

  const firstDescriptionId = isActivate
    ? "PhoneNumbers.Modal.ModalMessage.ACTIVATE_NUMBER"
    : "PhoneNumbers.Modal.ModalMessage.INACTIVATE_NUMBER";

  const firstDescriptionPart = formatMessage({ id: firstDescriptionId });
  const phoneNumber = modalData?.phone || "";
  const secondDescriptionPart = isActivate
    ? "."
    : ` ${formatMessage({ id: "PhoneNumbers.Modal.ModalMessage.INACTIVATE_NOTE" })}`;

  return (
    <Root
      open={isModalOpen}
      closeButton={true}
      onClose={handleCloseModal}
      actions={
        <Styles.ActionsContainer>
          <Close>
            <Styles.Button
              data-testid="cancel-button"
              size="medium"
              variant="secondary"
              onClick={handleCloseModal}
            >
              {formatMessage({ id: "PhoneNumbers.Modal.Button.CANCEL" })}
            </Styles.Button>
          </Close>
          <Close>
            <Styles.Button
              data-testid="action-button"
              size="medium"
              variant={isActivate ? "primary" : "destructive"}
              onClick={() => {
                modalData?.onClick();
                handleCloseModal();
              }}
            >
              {ActivationType}
            </Styles.Button>
          </Close>
        </Styles.ActionsContainer>
      }
      title={
        <Styles.TitleHeading data-testid="phone-action-modal" size="H3">
          {ActivationTitle}
        </Styles.TitleHeading>
      }
    >
      <Styles.Description data-testid="phone-description-modal">
        {firstDescriptionPart}{" "}
        {phoneNumber && (
          <>
            <Styles.Number data-testid="phone-number">
              {phoneNumber}
            </Styles.Number>
            {secondDescriptionPart}
          </>
        )}
      </Styles.Description>
    </Root>
  );
}
