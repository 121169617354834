import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const ContainerRate = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-1"]};
`;

export const NumberInfo = styled(Paragraph)<{ isInactive?: string }>`
  margin: 0.25rem 0;
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-6"]};
  color: ${tokens.color.foundation.txt.default};
  &[isInactive="INACTIVE"] {
    color: ${tokens.color.primitive.transparent.black["black-32"]};
  }
`;

export const Text = styled(Paragraph)<{
  weak?: boolean;
  isInactive?: string;
}>`
  color: ${(props) =>
    props.weak
      ? tokens.color.primitive.transparent.black["black-56"]
      : tokens.color.foundation.txt.default};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};

  &[isInactive="INACTIVE"] {
    color: ${tokens.color.primitive.transparent.black["black-32"]};
  }
`;
