import { useIntl } from "react-intl";

import { PhoneResponseType } from "@bees-grow-shared/services";

import RateMessage from "../rateMessage/RateMessage";
import PhoneActions from "./components/phoneActions/PhoneActions";
import { usePhoneRankItem } from "./hooks/usePhoneRankItem";
import * as Styles from "./PhoneRankItem.styles";

interface PhoneRankItemProps {
  phoneListData: PhoneResponseType;
}

export default function PhoneRankItem({ phoneListData }: PhoneRankItemProps) {
  const { formatMessage } = useIntl();
  const {
    phoneContact,
    phoneType,
    currentPhone,
    score,
    isConnected,
    tagLabel,
  } = usePhoneRankItem(phoneListData);

  return (
    <Styles.Wrapper data-testid="PhoneRankItem">
      <Styles.Header>
        <div>
          <Styles.Text data-testid="PhoneRankItem-name" is-inactive={score}>
            {phoneContact}
          </Styles.Text>
          <Styles.Text
            weak
            data-testid="PhoneRankItem-type"
            is-inactive={score}
          >
            {formatMessage({ id: `PhoneNumbers.PHONE_TYPE.${phoneType}` })}
          </Styles.Text>
        </div>
        {tagLabel && (
          <Styles.Tag
            data-testid="PhoneRankItem-tag"
            is-called-type={isConnected}
            label={tagLabel}
          />
        )}
      </Styles.Header>
      <Styles.Container>
        <Styles.ContainerInfo>
          <Styles.NumberInfo
            data-testid="PhoneRankItem-number"
            is-called-type={score}
          >
            {currentPhone}
          </Styles.NumberInfo>
          <PhoneActions phoneListData={phoneListData} />
        </Styles.ContainerInfo>
        <Styles.ContainerRate>
          <RateMessage score={score} data-testid="PhoneRankItem-rate-message" />
          <PhoneActions isRateType={true} phoneListData={phoneListData} />
        </Styles.ContainerRate>
      </Styles.Container>
    </Styles.Wrapper>
  );
}
