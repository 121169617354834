import { useEffect, useMemo, useRef, useState } from "react";

import {
  accountSharedService,
  Phone,
  phoneRankSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

const pageSize = 5;

interface UsePhoneRankProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  isLoading: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  error: boolean;
  currentPage: number;
  paginatedPhoneListData: Array<Phone>;
  setCurrentPage: (value: number | ((prev: number) => number)) => void;
  pageSize: number;
  total: number;
}

export function usePhoneRank(): UsePhoneRankProps {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const accountService = accountSharedService();
  const phoneRankService = phoneRankSharedService();
  const { phones, isLoading, error } = useSharedUnit(phoneRankService);
  const { accountId, vendorId } = useSharedUnit(accountService);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    phoneRankService.getPhoneRank({ accountId, vendorId });
  }, [accountId, phoneRankService, vendorId]);

  const paginatedPhoneListData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return phones.slice(startIndex, startIndex + pageSize);
  }, [phones, currentPage]);

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    isLoading,
    containerRef,
    error,
    paginatedPhoneListData,
    currentPage,
    setCurrentPage,
    pageSize,
    total: phones.length,
  };
}
