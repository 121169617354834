import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  LastOrdersStatusBadge,
  useLastOrdersStatusBadge,
} from "@bees-grow-shared/business-components";
import {
  lastOrdersSharedService,
  ORDER_STATUS,
  PaymentMethodEnum,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { OrderIdColumn } from "../components/orderIdColumn/OrderIdColumn";
import { PlacedColumn } from "../components/placedColumn/PlacedColumn";
import { ProductsColumn } from "../components/productsColumn/ProductsColumn";
import { TotalColumn } from "../components/totalColumn/TotalColumn";

interface TableRowData {
  channel: string;
  orderId: string;
  status: ORDER_STATUS;
  payment: string;
  placed: string;
  products: string;
  total: number;
}

export function useTablePreview() {
  const { formatMessage } = useIntl();
  const { getStatusDetails } = useLastOrdersStatusBadge();
  const lastOrdersService = lastOrdersSharedService();

  const { lastOrders } = useSharedUnit(lastOrdersService);

  const handleOnRowClick = (rowData: TableRowData) => ({
    onClick: () => {
      lastOrdersService.setLastOrdersState({
        lastOrderModalOrderId: rowData.orderId,
      });
    },
  });

  const getTranslation = (status: ORDER_STATUS) => {
    const translationId = getStatusDetails(status).translation;
    if (translationId) {
      return formatMessage({ id: translationId });
    }
    return status;
  };

  const parsedLastOrders: TableRowData[] = useMemo(
    () =>
      lastOrders.map((order) => ({
        channel: order.channel,
        orderId: order.orderNumber,
        status: order.status,
        payment: order.paymentMethod,
        placed: order.placementDate,
        products: String(order.products?.length),
        total: order.total,
      })),
    [lastOrders],
  );

  const columns = [
    {
      Header: formatMessage({ id: "LastOrders.Table.CHANNEL" }),
      accessor: "channel",
      disableSortBy: true,
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.ORDER_ID" }),
      accessor: "orderId",
      disableSortBy: true,
      customRender: (orderId: string) => <OrderIdColumn orderId={orderId} />,
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.STATUS" }),
      accessor: "status",
      disableSortBy: true,
      customRender: (status: ORDER_STATUS) => (
        <LastOrdersStatusBadge
          status={status}
          text={getTranslation(status)}
          variant="filled"
          responsive
        />
      ),
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.PAYMENT" }),
      accessor: "payment",
      disableSortBy: true,
      customRender: (payment: PaymentMethodEnum) =>
        formatMessage({ id: `PaymentMethod.Methods.${payment}` }),
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.PLACED_ON" }),
      accessor: "placed",
      disableSortBy: false,
      customRender: (placed: string) => <PlacedColumn placed={placed} />,
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.PRODUCTS" }),
      accessor: "products",
      disableSortBy: true,
      customRender: (products: string) => (
        <ProductsColumn products={products} />
      ),
    },
    {
      Header: formatMessage({ id: "LastOrders.Table.TOTAL" }),
      accessor: "total",
      disableSortBy: true,
      customRender: (total: number) => <TotalColumn total={total} />,
    },
  ];

  return { columns, parsedLastOrders, handleOnRowClick };
}
