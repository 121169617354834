import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { ClipboardButton } from "@bees-grow-shared/components";
import { Tooltip } from "@hexa-ui/components";

import * as Styled from "./OrderIdColumn.styles";

interface OrderIdColumnProps {
  orderId: string;
}

export function OrderIdColumn({ orderId }: Readonly<OrderIdColumnProps>) {
  const textRef = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const { formatMessage } = useIntl();

  const checkEllipsis = useCallback(() => {
    if (textRef.current && textRef.current.scrollWidth) {
      setIsEllipsisActive(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }
  }, [textRef, setIsEllipsisActive]);

  useLayoutEffect(() => {
    window.addEventListener("resize", checkEllipsis);
    checkEllipsis();

    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, [checkEllipsis]);

  const renderOrderId = () => {
    return <Styled.TextContainer ref={textRef}>{orderId}</Styled.TextContainer>;
  };

  const renderContent = () => {
    if (isEllipsisActive) {
      return (
        <Tooltip placement="top" text={orderId}>
          {renderOrderId()}
        </Tooltip>
      );
    }
    return renderOrderId();
  };

  return (
    <Styled.Container>
      <ClipboardButton
        textToCopy={orderId}
        positionTooltip="top"
        tooltipCopiedText={formatMessage({ id: "LastOrders.COPY_CLIPBOARD" })}
        tooltipCopyText={formatMessage({ id: "LastOrders.COPY_CLIPBOARD" })}
        testId="clipboard-button"
      />
      <Styled.ContentContainer>{renderContent()}</Styled.ContentContainer>
    </Styled.Container>
  );
}
