import { useIntl } from "react-intl";

import * as Styled from "./ClubB.styles";

export default function ClubB() {
  const { formatMessage } = useIntl();

  return (
    <Styled.Container>
      <Styled.Title size="H4" data-testid={`${ClubB.name}-title`}>
        {formatMessage({ id: "ClubB.TITLE" })}
      </Styled.Title>
    </Styled.Container>
  );
}
