import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Wrapper = styled.ul<{ isDropdownOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: ${(props) => (props.isDropdownOpen ? "56.25rem" : "0")};
  transition: max-height 500ms ease-in-out;

  & > li:nth-child(even) {
    background-color: ${tokens.color.foundation.bg["primary-weak"]};
  }

  & > li:nth-child(odd) {
    background-color: transparent;
  }
`;

export const PaginationContainer = styled.div`
  margin: 0.5rem 0.625rem;
  width: 93.86503%;

  & > div {
    width: 100%;

    & > nav {
      width: 100%;

      & > ul {
        width: 100%;

        & > li {
          width: 100%;
        }
      }
    }
  }
`;
