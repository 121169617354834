import React from "react";
import { useIntl } from "react-intl";

import { Phone } from "@bees-grow-shared/services";

import ActionList from "../actionList/ActionList";
import RateMessage from "../rateMessage/RateMessage";
import * as Styles from "./PhoneRankItem.styles";

interface PhoneRankItemProps {
  phoneListData: Phone;
}

const PhoneRankItem: React.FC<PhoneRankItemProps> = ({ phoneListData }) => {
  const { phoneContact, phoneType, phoneNumber, score } = phoneListData;
  const testIdPrefix = PhoneRankItem.name;
  const { formatMessage } = useIntl();

  return (
    <Styles.Wrapper data-testid={testIdPrefix}>
      <div>
        <Styles.Text data-testid={`${testIdPrefix}-name`} isInactive={score}>
          {phoneContact}
        </Styles.Text>
        <Styles.Text
          weak
          data-testid={`${testIdPrefix}-type`}
          isInactive={score}
        >
          {formatMessage({ id: `PhoneNumbers.PHONE_TYPE.${phoneType}` })}
        </Styles.Text>
      </div>
      <Styles.Container>
        <Styles.ContainerInfo>
          <Styles.NumberInfo
            data-testid={`${testIdPrefix}-number`}
            isInactive={score}
          >
            {phoneNumber}
          </Styles.NumberInfo>
          <ActionList score={score} />
        </Styles.ContainerInfo>
        <Styles.ContainerRate>
          <RateMessage
            score={score}
            data-testid={`${testIdPrefix}-rate-message`}
          />
          <ActionList type="rate" score={score} />
        </Styles.ContainerRate>
      </Styles.Container>
    </Styles.Wrapper>
  );
};

export default PhoneRankItem;
