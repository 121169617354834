import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const EmptyContainer = styled.div`
  margin: 0 auto 1.5rem auto;
  width: 15rem;
  text-align: center;
`;

export const EmptyText = styled(Paragraph)`
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  font-weight: ${tokens.fonts["font-weight"].normal};
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  text-align: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  li {
    background: white;
  }
  li:nth-child(4n-1),
  li:nth-child(4n-2) {
    background: ${tokens.color.primitive.solid.neutral["neutral-10"]};
  }
`;
