export default function EmptyStateMediumIcon({
  width = "128",
  height = "128",
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 128 128"
    >
      <g clipPath="url(#clip0_10905_5719)">
        <g opacity="0.3">
          <mask
            id="mask0_10905_5719"
            style={{ maskType: "luminance" }}
            width="58"
            height="58"
            x="67"
            y="21"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M95.659 78.89c15.815 0 28.636-12.82 28.636-28.636 0-15.816-12.821-28.637-28.636-28.637-15.816 0-28.637 12.821-28.637 28.637 0 15.815 12.821 28.636 28.637 28.636z"
            ></path>
          </mask>
          <g mask="url(#mask0_10905_5719)">
            <path
              fill="url(#paint0_linear_10905_5719)"
              d="M95.659 78.89c15.815 0 28.636-12.82 28.636-28.636 0-15.816-12.821-28.637-28.636-28.637-15.816 0-28.637 12.821-28.637 28.637 0 15.815 12.821 28.636 28.637 28.636z"
            ></path>
          </g>
        </g>
        <g opacity="0.3">
          <mask
            id="mask1_10905_5719"
            style={{ maskType: "luminance" }}
            width="50"
            height="50"
            x="-2"
            y="61"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M23.026 110.179c13.424 0 24.305-10.882 24.305-24.306 0-13.424-10.882-24.306-24.305-24.306C9.602 61.567-1.28 72.45-1.28 85.873c0 13.424 10.882 24.306 24.306 24.306z"
            ></path>
          </mask>
          <g mask="url(#mask1_10905_5719)">
            <path
              fill="url(#paint1_linear_10905_5719)"
              d="M23.026 110.179c13.424 0 24.305-10.882 24.305-24.306 0-13.424-10.882-24.306-24.305-24.306C9.602 61.567-1.28 72.45-1.28 85.873c0 13.424 10.882 24.306 24.306 24.306z"
            ></path>
          </g>
        </g>
        <mask
          id="mask2_10905_5719"
          style={{ maskType: "luminance" }}
          width="127"
          height="107"
          x="0"
          y="3"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M104.832 67.705c0 23.125-18.745 41.877-41.877 41.877-23.133 0-41.878-18.745-41.878-41.877l-20.593.298L8.747 3.77h111.452l6.521 64.234-21.888-.298z"
          ></path>
        </mask>
        <g mask="url(#mask2_10905_5719)">
          <mask
            id="mask3_10905_5719"
            style={{ maskType: "luminance" }}
            width="84"
            height="85"
            x="21"
            y="25"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M62.969 109.547c23.128 0 41.877-18.75 41.877-41.878S86.097 25.792 62.969 25.792c-23.128 0-41.877 18.75-41.877 41.877 0 23.129 18.749 41.878 41.877 41.878z"
            ></path>
          </mask>
          <g mask="url(#mask3_10905_5719)">
            <path
              fill="url(#paint2_linear_10905_5719)"
              d="M62.969 109.547c23.128 0 41.877-18.75 41.877-41.878S86.097 25.792 62.969 25.792c-23.128 0-41.877 18.75-41.877 41.877 0 23.129 18.749 41.878 41.877 41.878z"
            ></path>
          </g>
        </g>
        <mask
          id="mask4_10905_5719"
          style={{ maskType: "luminance" }}
          width="127"
          height="107"
          x="0"
          y="3"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M104.832 67.705c0 23.125-18.745 41.877-41.877 41.877-23.133 0-41.878-18.745-41.878-41.877l-20.593.298L8.747 3.77h111.452l6.521 64.234-21.888-.298z"
          ></path>
        </mask>
        <g mask="url(#mask4_10905_5719)">
          <path
            fill="#E5D770"
            d="M86.514 49.756l10.425-7.971a1.44 1.44 0 011.97.206l9.6 11.236c.547.64.433 1.614-.256 2.112l-11.591 8.305-10.148-13.888zM40.846 49.756l-10.432-7.971a1.44 1.44 0 00-1.97.206l-9.6 11.236a1.445 1.445 0 00.257 2.112l11.598 8.305 10.147-13.888z"
          ></path>
          <path
            fill="#8C7C1B"
            d="M96.569 63.644h-65.87l10.147-13.888H86.43l10.14 13.888z"
          ></path>
          <path
            fill="url(#paint3_linear_10905_5719)"
            d="M96.569 63.644h-65.87v65.87h65.87v-65.87z"
          ></path>
          <path
            fill="#000"
            d="M72.697 19.356h-18.12a4.512 4.512 0 00-3.178 1.316L37.89 34.183a4.482 4.482 0 00-1.317 3.179V55.48c0 1.195.477 2.332 1.316 3.179L51.4 72.17a4.482 4.482 0 003.179 1.316h18.119a4.512 4.512 0 003.179-1.315l13.51-13.511a4.482 4.482 0 001.316-3.18V37.363a4.512 4.512 0 00-1.315-3.179L75.876 20.672a4.525 4.525 0 00-3.179-1.316z"
            opacity="0.16"
          ></path>
          <path
            fill="#D85951"
            d="M73.18 15.985H54.094a4.73 4.73 0 00-3.349 1.387L36.501 31.608a4.73 4.73 0 00-1.386 3.35V54.05a4.73 4.73 0 001.386 3.35l14.237 14.236a4.73 4.73 0 003.35 1.387H73.18a4.73 4.73 0 003.35-1.387L90.766 57.4a4.73 4.73 0 001.387-3.35V34.958a4.73 4.73 0 00-1.387-3.35L76.53 17.372a4.73 4.73 0 00-3.35-1.387z"
          ></path>
          <path
            fill="#D85951"
            d="M72.377 18.389h-17.48a4.309 4.309 0 00-3.064 1.273L38.791 32.696a4.351 4.351 0 00-1.273 3.065v17.48a4.31 4.31 0 001.273 3.064l13.042 13.042c.81.81 1.92 1.273 3.065 1.273h17.479a4.31 4.31 0 003.065-1.273l13.042-13.042a4.351 4.351 0 001.273-3.065V35.761a4.31 4.31 0 00-1.273-3.065L75.442 19.655a4.332 4.332 0 00-3.065-1.266z"
          ></path>
          <path
            fill="#fff"
            d="M61.476 58.872c-.57-.583-.854-1.322-.854-2.225 0-.904.285-1.636.854-2.205.569-.569 1.301-.853 2.204-.853.903 0 1.643.284 2.226.853.583.57.874 1.301.874 2.205 0 .903-.291 1.642-.874 2.225-.583.584-1.323.875-2.226.875s-1.636-.291-2.204-.875zm.569-9.5a.51.51 0 01-.15-.37l-.483-19.221c0-.142.05-.27.156-.37.1-.1.228-.15.37-.15h4.018a.51.51 0 01.519.52l-.477 19.221c0 .15-.05.27-.156.37-.1.1-.228.156-.37.156h-3.058a.49.49 0 01-.37-.156z"
          ></path>
          <path
            fill="#fff"
            d="M72.377 20.28c.654 0 1.266.256 1.728.711l13.042 13.042c.462.462.718 1.074.718 1.728v17.48c0 .654-.256 1.265-.711 1.727L74.112 68.01a2.42 2.42 0 01-1.728.711H54.898a2.449 2.449 0 01-1.728-.711L40.128 54.968a2.426 2.426 0 01-.718-1.728V35.761c0-.654.256-1.266.711-1.728l13.042-13.042a2.42 2.42 0 011.728-.71h17.486zm0-1.891h-17.48a4.309 4.309 0 00-3.064 1.273L38.791 32.696a4.351 4.351 0 00-1.273 3.065v17.48a4.31 4.31 0 001.273 3.064l13.042 13.042c.81.81 1.92 1.273 3.065 1.273h17.479a4.31 4.31 0 003.065-1.273l13.042-13.042a4.351 4.351 0 001.273-3.065V35.761a4.31 4.31 0 00-1.273-3.065L75.442 19.655a4.332 4.332 0 00-3.065-1.266z"
          ></path>
          <path
            fill="#E5D770"
            d="M30.791 63.644H96.62l3.57 12.608c.554 1.955-.946 3.89-3.008 3.89H30.237c-2.063 0-3.563-1.935-3.008-3.89l3.562-12.608z"
          ></path>
          <path
            fill="#000"
            d="M96.562 80.148H30.734v3.464h65.828v-3.464z"
            opacity="0.16"
          ></path>
        </g>
        <path
          fill="#000"
          d="M84.729 61.567H42.596l2.005 2.077h38.002l2.126-2.077z"
          opacity="0.16"
        ></path>
        <g opacity="0.5">
          <mask
            id="mask5_10905_5719"
            style={{ maskType: "luminance" }}
            width="15"
            height="15"
            x="11"
            y="36"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M18.532 50.915a7.218 7.218 0 100-14.436 7.218 7.218 0 000 14.436z"
            ></path>
          </mask>
          <g mask="url(#mask5_10905_5719)">
            <path
              fill="url(#paint4_linear_10905_5719)"
              d="M18.532 50.915a7.218 7.218 0 100-14.436 7.218 7.218 0 000 14.436z"
            ></path>
          </g>
        </g>
        <g opacity="0.5">
          <mask
            id="mask6_10905_5719"
            style={{ maskType: "luminance" }}
            width="16"
            height="15"
            x="101"
            y="97"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M109.056 111.879a7.219 7.219 0 10-.001-14.437 7.219 7.219 0 00.001 14.437z"
            ></path>
          </mask>
          <g mask="url(#mask6_10905_5719)">
            <path
              fill="url(#paint5_linear_10905_5719)"
              d="M109.056 111.879a7.219 7.219 0 10-.001-14.437 7.219 7.219 0 00.001 14.437z"
            ></path>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10905_5719"
          x1="67.021"
          x2="124.299"
          y1="50.251"
          y2="50.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_10905_5719"
          x1="-1.28"
          x2="47.335"
          y1="85.871"
          y2="85.871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_10905_5719"
          x1="21.097"
          x2="104.846"
          y1="67.669"
          y2="67.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_10905_5719"
          x1="63.636"
          x2="63.636"
          y1="129.519"
          y2="63.646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AF32"></stop>
          <stop offset="1" stopColor="#D8BC32"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_10905_5719"
          x1="11.313"
          x2="25.752"
          y1="43.696"
          y2="43.696"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_10905_5719"
          x1="101.834"
          x2="116.274"
          y1="104.663"
          y2="104.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFE0E4"></stop>
          <stop offset="1" stopColor="#EEEFF1"></stop>
        </linearGradient>
        <clipPath id="clip0_10905_5719">
          <path fill="#fff" d="M0 0H128V128H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
