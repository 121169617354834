import { useIntl } from "react-intl";

import * as Styled from "./Notes.styles";

export default function Notes() {
  const { formatMessage } = useIntl();

  return (
    <Styled.Container>
      <Styled.Title size="H4" data-testid={`${Notes.name}-title`}>
        {formatMessage({ id: "Notes.TITLE" })}
      </Styled.Title>
    </Styled.Container>
  );
}
