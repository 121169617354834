import { useIntl } from "react-intl";

import { Tooltip } from "@hexa-ui/components";

import * as Styles from "./ActionButton.styles";

interface ActionButtonProps {
  actionIcon: React.ReactElement;
}

export default function ActionButton({ actionIcon }: ActionButtonProps) {
  const { formatMessage } = useIntl();
  const translationKey = actionIcon.key?.toUpperCase();

  return (
    <Tooltip
      placement="bottom"
      text={formatMessage({ id: `PhoneNumbers.TOOLTIP.${translationKey}` })}
    >
      <Styles.IconButton
        hovervariant={actionIcon.key}
        variant="secondary"
        icon={() => actionIcon}
        size="small"
        data-testid={`${ActionButton.name}-button-phone-off`}
      ></Styles.IconButton>
    </Tooltip>
  );
}
