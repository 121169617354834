import { useIntl } from "react-intl";

import { ChevronDown, ChevronUp } from "@hexa-ui/icons";

import * as Styles from "./PhoneRankHeader.styles";

interface PhoneRankHeaderProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  hasError: boolean;
}

export default function PhoneRankHeader({
  isDropdownOpen,
  setIsDropdownOpen,
  hasError,
}: Readonly<PhoneRankHeaderProps>) {
  const { formatMessage } = useIntl();

  function handleDropdown() {
    setIsDropdownOpen((prev) => !prev);
  }

  return (
    <Styles.CardHeader
      data-has-error={hasError}
      data-testid={`${PhoneRankHeader.name}`}
    >
      <Styles.HeaderContent>
        <Styles.Title size="H4" data-testid={`${PhoneRankHeader.name}-title`}>
          {formatMessage({ id: "PhoneNumbers.TITLE" })}
        </Styles.Title>
        {!hasError && (
          <Styles.CollapsibleButton
            hasUnderline={false}
            onClick={handleDropdown}
            data-testid={`${PhoneRankHeader.name}-collapsible-button`}
          >
            {isDropdownOpen ? (
              <ChevronUp size="large" />
            ) : (
              <ChevronDown size="large" />
            )}
          </Styles.CollapsibleButton>
        )}
      </Styles.HeaderContent>
    </Styles.CardHeader>
  );
}
