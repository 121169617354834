import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Title = styled(Heading)`
  margin: 1.5rem 0 1rem 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
