import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleLoader = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-5"]};
  width: 8rem;
  margin-bottom: 1.5rem;
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem 1rem 1rem;
`;

export const PhoneColumn = styled.div``;

export const PhoneRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const PhoneIcon = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-7"]};
  width: ${tokens.measure["line-height"]["lineheight-7"]};
`;

export const PhoneColumnTitle = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-3"]};
  width: 8rem;
  margin: 0.5rem 0 1rem 0;
`;

export const PhoneColumnSubtitle = styled(SkeletonLoader)`
  height: 0.75rem;
  width: 7rem;
  margin-bottom: 0.25rem;
`;

export const PhoneColumnContent = styled(SkeletonLoader)`
  height: 0.75rem;
  width: 5rem;
`;
