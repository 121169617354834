import {
  OptimizelyWrapper,
  RouterWrapper,
  UserConfigWrapper,
} from "@bees-grow-shared/business-components";
import { ThemeProvider } from "@bees-grow-shared/theme";

import { EnvProvider } from "@/components/envProvider/EnvProvider";
import { EnvConfig } from "@/components/envProvider/EnvProvider.types";
import { IntlProvider } from "@/i18n";
import AccountPage from "@/pages/account/AccountPage";

export default function App(props: Readonly<EnvConfig>) {
  return (
    <ThemeProvider>
      <UserConfigWrapper>
        <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
          <EnvProvider env={props}>
            <IntlProvider>
              <RouterWrapper>
                <AccountPage />
              </RouterWrapper>
            </IntlProvider>
          </EnvProvider>
        </OptimizelyWrapper>
      </UserConfigWrapper>
    </ThemeProvider>
  );
}
